<form
  #form
  *ngIf="formGroup !== null && ticket"
  [formGroup]="formGroup"
  class="form-fields">
  <!-- TicketCategory Field -->
  <div class="form-field mb-2">
    <label htmlFor="subject" class="block">
      {{ "ticketComponent.attributes.ticketCategory" | translate}}*
    </label>
    <p-dropdown
      formControlName="ticketCategory"
      [options]="categoryChips"
      optionLabel="label"
      class="w-full mt-2 block"
      (onChange)="generateSubject()"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.ticketCategory' | translate)}">
      <ng-template let-item pTemplate="item">
        <div class="inline-block vertical-align-middle">
          <app-ticket-chip [label]="item"></app-ticket-chip>
        </div>
      </ng-template>
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div class="inline-flex align-items-center gap-2 px-1">
          <app-ticket-chip [label]="selectedItem"></app-ticket-chip>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
  <!-- Customer Field -->
  <div class="form-field mb-2">
    <label htmlFor="subject" class="block mb-2">
      {{ "ticketComponent.attributes.customer" | translate}}<ng-container
        *ngIf="isCustomerRequired"
        >*</ng-container
      >
    </label>
    <div class="flex align-items-center" *ngIf="!selectedCustomer">
      <span
        (mousedown)="chooseCustomerClicked()"
        class="pi pi-plus-circle text-primary cursor-pointer text-2xl mr-2"></span>
      <span
        >{{'general.form.chooseSubject'| translate: {subject:
        ('ticketComponent.attributes.customer' | translate)} }}</span
      >
    </div>
    <div class="flex justify-content-between" *ngIf="selectedCustomer">
      <app-customer-status-badge
        [clickable]="false"
        [customer]="selectedCustomer"></app-customer-status-badge>
      <div class="align-content-center">
        <span
          *ngIf="customerDeleteAllowed"
          (mousedown)="removeCustomerClicked()"
          class="pi pi-trash cursor-pointer text-red-700 text-xl mr-2"></span>
      </div>
    </div>

    <div class="form-field mb-2 mt-2" *ngIf="selectedCustomer">
      <label htmlFor="subject" class="block mb-2">
        {{ "ticketComponent.attributes.devices" | translate}}
      </label>
      <p-multiSelect
        appendTo="body"
        formControlName="devices"
        [options]="devices"
        [style]="{'width':'100%'}"
        [filter]="false"
        optionLabel="label"
        class="w-full mt-2 block"
        (onChange)="changeDevices($event)"
        [placeholder]="'general.choose'| translate"
        [dataKey]="'id'">
        <ng-template let-option pTemplate="item">
          <div class="inline-block vertical-align-middle">
            <div class="flex gap-3 align-items-center">
              <div class="flex flex-column white-space-nowrap gap-1">
                <div class="font-bold">{{option.device.title}}</div>
                <div *ngIf="deviceHasSerialNumber(option)">
                  {{getSerialNumberLabel(option)}}
                </div>
              </div>
            </div>
          </div>
        </ng-template>
        <ng-template let-options pTemplate="selectedItems">
          <div class="inline-block vertical-align-middle" *ngIf="options">
            <div
              class="inline-flex align-items-center gap-2 px-1"
              *ngFor="let option of options; index as i">
              <div class="font-bold">{{option.device.title}}</div>
              <div>
                <div *ngIf="deviceHasSerialNumber(option)">
                  {{getSerialNumberLabel(option)}}
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="!options">{{'general.choose'| translate}}</div>
        </ng-template>
      </p-multiSelect>
    </div>
  </div>
  <!-- Subject Field -->
  <div class="form-field mb-2">
    <label htmlFor="subject" class="block">
      {{ "ticketComponent.attributes.subject" | translate}}*
    </label>
    <input
      id="subject"
      pInputText
      formControlName="subject"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.subject' | translate)}"
      [value]="ticket.subject" />
  </div>
  <!-- RequestReason Field -->
  <div class="form-field mb-2">
    <label htmlFor="requestReason" class="block">
      {{ "ticketComponent.attributes.requestReason" | translate}}
    </label>
    <textarea
      id="requestReason"
      pInputText
      formControlName="requestReason"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.requestReason' | translate)}"
      [value]="ticket.requestReason"></textarea>
  </div>

  <!-- Description Field -->
  <div class="form-field mb-2">
    <label htmlFor="description" class="block">
      {{ "ticketComponent.attributes.description" | translate}}
    </label>
    <textarea
      id="description"
      pInputText
      formControlName="description"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.description' | translate)}"
      [value]="ticket.description"></textarea>
  </div>

  <div class="form-field mb-2 mt-2">
    <label htmlFor="subject" class="block mb-2">
      {{ "ticketComponent.attributes.editor" | translate}}*
    </label>
    <p-dropdown
      formControlName="editor"
      [options]="editors"
      [filter]="true"
      class="w-full mt-2 block"
      [placeholder]="'general.form.chooseSubject'| translate:
      {subject: ('ticketComponent.attributes.editor' | translate)}">
      <ng-template pTemplate="filter" let-options="options">
        <div class="flex gap-1">
          <div class="p-inputgroup" (click)="$event.stopPropagation()">
            <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
            <input
              type="text"
              pInputText
              placeholder="Filter"
              [value]="editorFilterValue"
              (input)="editorFilter($event)" />
          </div>
          <span
            class="pi pi-times cursor-pointer align-content-center text-red-700"
            (click)="resetFunction()"></span>
        </div>
      </ng-template>
      <ng-template let-item pTemplate="item">
        <div
          class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
          <app-user-avatar [user]="item" size="normal" />
          <span>{{item.firstname}} {{item.lastname}}</span>
        </div>
      </ng-template>
      <ng-template let-selectedItem pTemplate="selectedItem">
        <div
          *ngIf="selectedItem"
          class="inline-block vertical-align-middle flex gap-3 align-items-center white-space-nowrap">
          <app-user-avatar [user]="selectedItem" size="normal" />
          <span>{{selectedItem.firstname}} {{selectedItem.lastname}}</span>
        </div>
      </ng-template>
    </p-dropdown>
  </div>

  <div *ngIf="showReoccurring">
    <div class="form-field mb-2 mt-2">
      <label id="reoccurring-label" htmlFor="subject" class="block mb-2">
        {{ "ticketComponent.attributes.reoccurringTicket" | translate}}
      </label>
      <p-inputSwitch formControlName="isReoccurringTicket" class="mt-2 block">
      </p-inputSwitch>
    </div>
    <div class="form-field mb-2 mt-2" *ngIf="isReoccurringTicket">
      <label htmlFor="subject" class="block mb-2 align-content-end">
        {{ "ticketComponent.attributes.interval" | translate}}
      </label>
      <p-dropdown
        appendTo="body"
        [options]="reoccurring"
        optionLabel="label"
        class="w-full mt-2 block"
        (onChange)="onIsReoccurringTicketChange()"
        formControlName="selectedReoccurring"
        [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.interval' | translate)}">
        <ng-template let-item pTemplate="item">
          <div class="inline-block vertical-align-middle">
            {{'ticketComponent.attributes.intervalOptions.'+item | translate}}
          </div>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
          <div class="inline-flex align-items-center gap-2 px-1">
            {{'ticketComponent.attributes.intervalOptions.'+selectedItem |
            translate}}
          </div>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="form-field mb-2 mt-2" *ngIf="isReoccurringTicket">
      <label htmlFor="subject" class="block mb-2 align-content-end">
        {{ "ticketComponent.attributes.ExecutionDate" | translate}}
      </label>
      <p-calendar
        [firstDayOfWeek]="1"
        appendTo="body"
        [style]="{'width':'100%'}"
        [placeholder]="'ticketComponent.attributes.ExecutionDate' | translate"
        [readonlyInput]="true"
        formControlName="executionDate"
        [dateFormat]="'dd.mm.yy'"></p-calendar>
    </div>
  </div>

  <div>
    <div class="form-field mb-2">
      <label htmlFor="attachments" class="block mb-2">
        {{ "ticketComponent.attributes.attachments" | translate}}
      </label>
      <app-file-upload (choose)="filesChosen($event)">
        <p-button
          (onClick)="showCustomerDocuments()"
          icon="pi pi-link"
          iconPos="left"
          [disabled]="!selectedCustomer" />
      </app-file-upload>
      <app-files
        *ngIf="selectedFiles && selectedFiles.length > 0"
        context="customers"
        [filesList]="selectedFiles"
        [emitRemoveOnly]="true"
        (fileRemoved)="fileRemoved($event)"
        [selectionMode]="'none'"></app-files>
    </div>
  </div>

  <!-- FORMS - START -->
  <div *ngIf="selectedDevices.length !== 0" class="mt-4" formArrayName="forms">
    <h5 class="text-primary font-semibold mb-2">
      {{ "formComponent.table.title" | translate}}
    </h5>

    <div *ngFor="let group of groupedHiveForm;" class="mb-2">
      <!-- FORMS - LABEL - START -->
      <div class="mb-2">
        <div
          *ngIf="getSerialNumberLabel(group.customerDevice) !== ''"
          class="font-semibold">
          {{getSerialNumberLabel(group.customerDevice, " &")}}
        </div>
        <div class="text-sm">
          {{group.customerDevice.device?.title}}
          ({{group.customerDevice.device?.manufacturer?.name}})
        </div>
      </div>
      <!-- FORMS - LABEL - END -->

      <!-- FORMS - SELECT - START -->

      <div class="mb-1">
        <div
          *ngFor="let formCtx of group.forms"
          [formGroupName]="formCtx.originalIndex"
          class="flex align-items-center justify-content-between gap-2 mb-1">
          <!-- FORMS - FIELD - START -->

          <div class="form-field mb-2 flex-1">
            <label htmlFor="type" class="block">
              {{ "formComponent.attributes.forms" | translate}}
            </label>

            <p-dropdown
              formControlName="type"
              class="w-full mt-2 block"
              [options]="formTypes"
              optionLabel="label"
              optionValue="value"
              [placeholder]="'general.form.chooseSubject'| translate: {subject: ('formComponent.attributes.forms' | translate)}">
            </p-dropdown>
          </div>

          <div
            *ngIf="group.forms.length > 1"
            role="button"
            class="cursor-pointer text-primary border-1 border-primary border-circle p-2 flex align-items-center justify-content-center"
            (mousedown)="removeFormItem(formCtx.form.uuid)"
            style="margin-top: 17px">
            <i class="pi pi-trash"></i>
          </div>

          <div
            *ngIf="group.forms.length === 1"
            style="width: 30px; height: 30px"></div>

          <!-- FORMS - FIELD - END -->
        </div>
      </div>

      <!-- FORMS - SELECT - END -->

      <!-- FORMS - ADD BUTTON - START -->
      <div class="flex justify-content-start align-items-start mb-4 mt-1">
        <div
          role="button"
          class="cursor-pointer text-primary border-1 border-primary border-round flex align-items-center justify-content-center gap-1 px-3 py-1"
          (mousedown)="addFormItem(group.customerDevice)">
          <i class="pi pi-plus"></i>
          <span>{{'ticketComponent.actions.addForm' | translate}}</span>
        </div>
      </div>
      <!-- FORMS - ADD BUTTON - END -->
    </div>
  </div>
  <!-- FORMS - END -->
</form>

<p-dialog
  [draggable]="false"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [(visible)]="modalVisible"
  (onHide)="hideCustomerModal()"
  [modal]="true"
  [style]="{ width: '75%' }">
  <!-- Dialog Header -->
  <ng-template pTemplate="header">
    <h2 *ngIf="customerModalVisible">
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customer' | translate)} }}
    </h2>
    <h2 *ngIf="customerDocumentsModalVisible">
      {{'general.form.chooseSubject'| translate: {subject:
      ('ticketComponent.attributes.customerDocuments' | translate)} }}
    </h2>
  </ng-template>

  <app-customer-table
    *ngIf="customerModalVisible"
    [customers]="customers"
    [customerBadgeClickable]="false"
    [countries]="countries"
    [showEditAction]="false"
    [showDeleteAction]="false"
    [showCorrespondenceAction]="false"
    [showTicketAction]="false"
    [showCreateAction]="false"
    [showChooseAction]="true"
    [isLoading]="getCustomersIsLoading"
    (handleCustomerChosen)="onCustomerSelectionChanged($event)" />

  <app-customer-edit-documents
    *ngIf="selectedCustomer && customerDocumentsModalVisible"
    [customer]="selectedCustomer"
    (selectedFilesChange)="fileSelectionChanged($event)"
    selectionMode="multiple"
    [delegatePreview]="false"
    [(selectedFiles)]="selectedFiles"></app-customer-edit-documents>
  <ng-template pTemplate="footer">
    <div class="flex">
      <div class="text-left">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.cancel' | translate"
          (click)="hideCustomerModal()"
          class="flex-auto p-button-outlined"></button>
      </div>
      <div
        class="text-right"
        *ngIf="selectedCustomer && customerDocumentsModalVisible">
        <button
          pButton
          pRipple
          type="button"
          [label]="'general.form.take' | translate"
          (click)="takeCustomerDocuments()"
          class="flex-auto"></button>
      </div>
    </div>
  </ng-template>
</p-dialog>
