import { Component, OnInit } from '@angular/core';
import { take } from 'rxjs';

import { MyDayView } from 'src/app/models/Appointment';
import { AppointmentService } from 'src/app/services/api/appointment.service';

@Component({
  selector: 'app-my-day-overview',
  templateUrl: './my-day-overview.component.html',
  styleUrl: './my-day-overview.component.scss'
})
export class MyDayOverviewComponent implements OnInit {
  myDayView: MyDayView | null = null;

  constructor(private readonly appointmentService: AppointmentService) {}

  ngOnInit(): void {
    this.appointmentService
      .getMyDayView()
      .pipe(take(1))
      .subscribe((myDayView) => {
        this.myDayView = myDayView;
      });
  }
}
