import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import * as moment from 'moment';
import { first, Subscription } from 'rxjs';
import { AppointmentDto, MyDayView } from 'src/app/models/Appointment';
import { Country } from 'src/app/models/Country';
import { CustomerDevice } from 'src/app/models/customer/CustomerDevice';
import { HiveForm } from 'src/app/models/HiveForm';
import { CountryService } from 'src/app/services/api/country.service';
import { FormDeadlineService } from 'src/app/services/utils/form-deadline.service';
import { isFormCompleted } from 'src/utils/is-form-completed';
import { getBackgroundColor, getIcon } from 'src/utils/ticket-helper';

@Component({
  selector: 'app-day-card',
  templateUrl: './day-card.component.html',
  styleUrls: ['./day-card.component.scss']
})
export class DayCardComponent implements OnInit, OnChanges, OnDestroy {
  @Input({ required: true }) myDayView!: MyDayView;

  detailView: AppointmentDto | null = null;

  visible: boolean = false;

  countries: Country[] = [];

  country: Country | null = null;

  customerDevices: CustomerDevice[] = [];

  isDisabled: boolean = false;

  deadlines: Map<number, { text: string; time: string; count: number }> =
    new Map();

  private subscriptions: Subscription[] = [];

  constructor(
    private readonly countryService: CountryService,
    private readonly formDeadlineService: FormDeadlineService
  ) {}

  ngOnInit() {
    this.countryService
      .findAll()
      .pipe(first())
      .subscribe((countries) => {
        this.countries = countries;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['myDayView']) {
      if (this.detailView) {
        this.startDeadlineTimerForDetailView();
      }
    }
  }

  startDeadlineTimerForDetailView() {
    if (!this.detailView?.forms || this.isDisabled) {
      this.deadlines.clear(); // Clear all deadlines
      if (this.detailView?.forms) {
        this.detailView.forms.forEach((form) => {
          if (form.id) {
            this.formDeadlineService.stopTimer(form.id); // Stop previous timer
          }
        });
      }

      return;
    }

    // Clear previous deadlines
    this.deadlines.clear();

    this.detailView.forms.forEach((form) => {
      if (form.deadline && form.id) {
        const deadlineDate = new Date(form.deadline);

        // Start the timer with the deadline date and get the observable
        const deadlineObservable = this.formDeadlineService.startTimer(
          deadlineDate,
          1000,
          form.id
        );

        // Subscribe to deadline updates
        const subscription = deadlineObservable.subscribe((deadline) => {
          if (form.id && deadline) {
            this.deadlines.set(form.id, deadline); // Update the deadlines map
          }
        });
        this.subscriptions.push(subscription); // Store the subscription
      }
    });
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.subscriptions = [];
    this.deadlines.clear();
    if (this.detailView?.forms) {
      this.detailView?.forms.forEach((form) => {
        if (form.id) {
          this.formDeadlineService.stopTimer(form.id);
        }
      });
    }
  }

  handleSwitchView(appointment: AppointmentDto) {
    this.clearSubscriptions(); // Clear previous subscriptions and timers
    this.detailView = appointment;

    this.country =
      this.countries.find(
        (c) =>
          c.id === this.detailView?.ticket?.customer?.facilityAddress.countryId
      ) || null;

    this.customerDevices = this.detailView?.ticket?.customerDevices || [];

    this.startDeadlineTimerForDetailView();
    this.visible = true;
  }

  formatDate(date: string): string {
    return moment(date).format('DD.MM.YYYY');
  }

  getDayName(date: string): string {
    return moment(date).format('dddd');
  }

  formatTime(time: string): string {
    return moment(time).format('HH:mm');
  }

  formatHoursRemaining(deadline: Date): number {
    return moment(deadline).diff(moment(), 'hours');
  }

  formCheck(form: HiveForm): string {
    return isFormCompleted(form) ? 'pi pi-check-circle' : 'pi pi-pencil';
  }

  get customerContactPersonName(): string {
    const contactPerson = this.detailView?.ticket?.customerContactPerson;

    if (!contactPerson) {
      return '';
    }

    const firstname = contactPerson?.firstname;
    const lastname = contactPerson?.lastname;

    return firstname ? `${firstname} ${lastname}` : lastname;
  }

  get backgroundColor(): string {
    return this.detailView?.ticket
      ? getBackgroundColor(this.detailView.ticket)
      : '';
  }

  get icon(): string {
    return this.detailView?.ticket ? getIcon(this.detailView.ticket) : '';
  }
}
