<form autocomplete="off" [formGroup]="form">
  <div class="form-field mb-2">
    <label htmlFor="subject" class="block">
      {{ "ticketComponent.attributes.subject" | translate}}*
    </label>
    <input
      id="subject"
      pInputText
      formControlName="subject"
      type="text"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.subject' | translate)}"
      [value]="ticket.subject"
      (input)="onTicketChanged()" />
  </div>
  <!-- RequestReason Field -->
  <div class="form-field mb-2">
    <label htmlFor="requestReason" class="block">
      {{ "ticketComponent.attributes.requestReason" | translate}}
    </label>
    <textarea
      id="requestReason"
      pInputText
      formControlName="requestReason"
      type="text"
      rows="10"
      cols="30"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.requestReason' | translate)}"
      [value]="ticket.requestReason"
      (input)="onTicketChanged()"></textarea>
  </div>
  <!-- Description Field -->
  <div class="form-field mb-2">
    <label htmlFor="description" class="block">
      {{ "ticketComponent.attributes.description" | translate}}
    </label>
    <textarea
      id="description"
      pInputText
      formControlName="description"
      type="text"
      rows="10"
      cols="30"
      class="w-full mt-2 block"
      [placeholder]="'general.form.enterSubject'| translate:
      {subject: ('ticketComponent.attributes.description' | translate)}"
      [value]="ticket.description"
      (input)="onTicketChanged()"></textarea>
  </div>
</form>
