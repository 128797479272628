<!-- Detail View Dialog -->
<p-dialog
  *ngIf="detailView"
  [(visible)]="visible"
  [modal]="true"
  [dismissableMask]="true"
  [closable]="true"
  [draggable]="false"
  class="detail-view-dialog">
  <ng-template pTemplate="header">
    <div class="flex-1 flex-column">
      <div class="detail-label">
        {{detailView.ticket?.ticketNumber}}
        <a
          [routerLink]="'/tickets/' + detailView.ticketId"
          class="pi pi-external-link link pl-1"></a>
      </div>
      <div class="font-bold">{{detailView.title}}</div>
    </div>
    <div class="ticket-category" [style]="{backgroundColor: backgroundColor}">
      <span [class]="'pi '+icon"></span>
      {{ 'ticketComponent.ticketChips.' +detailView.ticket?.ticketCategoryType
      |translate}}
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <div class="flex mb-4 align-items-start">
      <div class="flex-1 flex flex-column gap-4">
        <div *ngIf="detailView.ticket?.requestReason">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.requestReason" | translate}}
          </div>
          <div>{{detailView.ticket?.requestReason}}</div>
        </div>
        <!-- Checks which descriptions exist. If both have same content, prioritize ticket.description. -->
        <div
          *ngIf="detailView.description || detailView.ticket?.description"
          class="flex flex-column gap-4">
          <div
            *ngIf="detailView.description && (!detailView.ticket?.description || detailView.description !== detailView.ticket?.description)">
            <div class="detail-label">
              {{"myDayComponent.detailView.labels.description" | translate}}
            </div>
            <div>{{detailView.description}}</div>
          </div>
          <div *ngIf="detailView.ticket?.description">
            <div class="detail-label">
              {{"myDayComponent.detailView.labels.ticketDescription" |
              translate}}
            </div>
            <div>{{detailView.ticket?.description}}</div>
          </div>
        </div>

        <div *ngIf="detailView.ticket?.customer">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.customer" | translate}}
            <a
              [routerLink]="'/customers/' + detailView.ticket?.customerId"
              class="pi pi-external-link link pl-1"></a>
          </div>
          <div>
            <div>
              {{detailView.ticket?.customer?.name}}
              ({{detailView.ticket?.customer?.customerNumber}})
            </div>

            <div class="smaller">
              {{detailView.ticket?.customer?.billingAddressEmail}}
            </div>
          </div>
        </div>
        <div *ngIf="detailView.ticket?.customer?.facilityAddress">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.facilityAddress" | translate}}
          </div>
          <div>
            {{"customerComponent.facilityAddressType." +
            detailView.ticket?.customer?.facilityAddressType | translate}}
            <br />
            {{detailView.ticket?.customer?.facilityAddress?.street}} <br />
            {{detailView.ticket?.customer?.facilityAddress?.postalCode}}
            {{detailView.ticket?.customer?.facilityAddress?.city}}, {{
            ("countries." + country?.key) | translate }}
          </div>
        </div>
        <div *ngIf="detailView.ticket?.customerContactPerson">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.contact" | translate}}
          </div>
          <div>
            <div>
              {{ customerContactPersonName}}
              ({{"customerContactPersonComponent.attributes.type.enum." +
              detailView.ticket?.customerContactPerson?.type | translate}})
            </div>
            <div>
              <span class="pi pi-mobile"></span>
              <a
                class="link"
                href="tel:{{detailView.ticket?.customerContactPerson?.phoneNumberMobile}}">
                {{detailView.ticket?.customerContactPerson?.phoneNumberMobile}}</a
              >
            </div>
            <div>
              <span class="pi pi-phone"></span>
              <a
                class="link"
                href="tel:{{detailView.ticket?.customerContactPerson?.phoneNumberLandline}}">
                {{ detailView.ticket?.customerContactPerson?.phoneNumberLandline
                }}</a
              >
            </div>
          </div>
        </div>
      </div>
      <div class="detail-card-right card flex flex-column gap-4">
        <div>
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.start" | translate}}
          </div>
          <div>
            <span class="pi pi-clock"></span>
            {{formatDate(detailView.start)}} {{formatTime(detailView.start)}}
          </div>
        </div>
        <div>
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.end" | translate}}
          </div>
          <div>
            <span class="pi pi-clock"></span>
            {{formatDate(detailView.end)}} {{formatTime(detailView.end)}}
          </div>
        </div>
        <div *ngIf="detailView.technician">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.technician" | translate}}
          </div>
          <div class="flex align-items-center">
            <app-user-avatar
              [user]="detailView.technician"
              size="small"
              class="pr-2" />
            <div>
              {{detailView.technician.firstname}}
              {{detailView.technician.lastname}}
            </div>
          </div>
        </div>
        <div *ngIf="detailView.ticket?.editor as editor">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.editor" | translate}}
          </div>
          <div class="flex align-items-center">
            <app-user-avatar [user]="editor" size="small" class="pr-2" />
            <div>{{editor.firstname}} {{editor.lastname}}</div>
          </div>
        </div>
        <div *ngIf="detailView.notes">
          <div class="detail-label">
            {{"myDayComponent.detailView.labels.notes" | translate}}
          </div>
          <div>{{detailView.notes}}</div>
        </div>
      </div>
    </div>
    <div class="flex flex-column gap-4">
      <div *ngIf="customerDevices.length > 0">
        <div class="detail-label">
          {{"myDayComponent.detailView.labels.devices.title" | translate}}
        </div>
        <table class="w-full">
          <tr>
            <th>
              {{"myDayComponent.detailView.labels.devices.device" | translate}}
            </th>
            <th>
              {{"myDayComponent.detailView.labels.devices.serialNumber" |
              translate}}
            </th>
            <th>
              {{"myDayComponent.detailView.labels.devices.actions" | translate}}
            </th>
          </tr>

          <tr
            *ngFor="let device of customerDevices; let i = index"
            [ngClass]="{'bordered-row': i < customerDevices.length - 1}">
            <td>
              <div class="smaller">{{device.device?.manufacturer?.name}}</div>
              <div>{{device.device?.title}}</div>
            </td>
            <td>
              <div *ngIf="device.internalDeviceSerialNumber">
                {{"myDayComponent.detailView.labels.devices.internal" |
                translate}}: {{device.internalDeviceSerialNumber}}
              </div>
              <div *ngIf="device.externalDeviceSerialNumber">
                {{"myDayComponent.detailView.labels.devices.external" |
                translate}}: {{device.externalDeviceSerialNumber}}
              </div>
            </td>
            <td>
              <a
                [routerLink]="'/tickets/' + detailView.ticketId + '/4'"
                class="pi pi-external-link link"></a>
            </td>
          </tr>
        </table>
      </div>
      <div *ngIf="detailView.forms && detailView.forms.length > 0">
        <div class="detail-label">
          {{"myDayComponent.detailView.labels.forms" | translate}}
          <a
            [routerLink]="'/tickets/' + detailView.ticketId + '/7'"
            class="pi pi-external-link link text-right"></a>
        </div>
        <div class="form-container gap-2">
          <div *ngFor="let form of detailView.forms">
            <div class="card form-card flex">
              <div class="flex" [ngStyle]="{minWidth: '2rem'}">
                <span [ngClass]="formCheck(form)"></span>
              </div>

              <div class="flex-1 flex flex-column">
                <div class="font-bold">
                  {{'formComponent.attributes.formTypes.' + form.type |
                  translate}}
                </div>
                <div class="smaller">
                  {{form.customerDevice?.device?.manufacturer?.name}}
                </div>
                <div>{{form.customerDevice?.device?.title}}</div>
                <div *ngIf="form.customerDevice?.internalDeviceSerialNumber">
                  {{"myDayComponent.detailView.labels.devices.internal" |
                  translate}}:
                  {{form.customerDevice?.internalDeviceSerialNumber}}
                </div>
                <div *ngIf="form.customerDevice?.externalDeviceSerialNumber">
                  {{"myDayComponent.detailView.labels.devices.external" |
                  translate}}:
                  {{form.customerDevice?.externalDeviceSerialNumber}}
                </div>

                <div *ngIf="form.id && deadlines.has(form.id)" class="deadline">
                  <span class="pi pi-clock"></span>
                  {{ (deadlines.get(form.id)?.text || "" | translate: {time:
                  deadlines.get(form.id)?.time || "" | translate : { count:
                  deadlines.get(form.id)?.count || 0 } }) }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</p-dialog>

<!-- Overview -->
<div class="flex justify-content-between h-full">
  <div class="day-card mr-6 card">
    <div class="day-card-header flex align-items-center">
      <div class="day-card-date">
        <span class="font-bold uppercase">
          {{getDayName(myDayView.currentRelevant.date)}}
        </span>
        <span> ({{formatDate(myDayView.currentRelevant.date)}}) </span>
      </div>
      <div class="appointment-count">
        {{myDayView.currentRelevant.appointments.length}}
      </div>
    </div>
    <div class="flex align-items-stretch flex-1 overflow-auto">
      <div class="sideline-container">
        <div class="sideline"></div>
      </div>
      <div class="flex-1">
        <div *ngFor="let appointment of myDayView.currentRelevant.appointments">
          <app-appointment-my-day
            (handleSwitchView)="handleSwitchView($event)"
            [appointment]="appointment" />
        </div>
        <div [ngStyle]="{height: '8px'}"></div>
      </div>
    </div>
  </div>

  <div class="day-card card">
    <div class="day-card-header flex align-items-center">
      <div class="day-card-date">
        <span class="font-bold uppercase">
          {{getDayName(myDayView.nextRelevant.date)}}
        </span>
        <span> ({{formatDate(myDayView.nextRelevant.date)}}) </span>
      </div>
      <div class="appointment-count">
        {{myDayView.nextRelevant.appointments.length}}
      </div>
    </div>
    <div class="flex align-items-stretch flex-1 overflow-auto">
      <div class="sideline-container">
        <div class="sideline"></div>
      </div>
      <div class="flex-1">
        <div *ngFor="let appointment of myDayView.nextRelevant.appointments">
          <app-appointment-my-day
            (handleSwitchView)="handleSwitchView($event)"
            [appointment]="appointment" />
        </div>
        <div [ngStyle]="{height: '8px'}"></div>
      </div>
    </div>
  </div>

  <div class="day-card ml-6 card">
    <div class="day-card-header flex align-items-center">
      <div class="day-card-date">
        <span class="font-bold uppercase">
          {{getDayName(myDayView.beyondNextRelevant.date)}}
        </span>
        <span> ({{formatDate(myDayView.beyondNextRelevant.date)}}) </span>
      </div>
      <div class="appointment-count">
        {{myDayView.beyondNextRelevant.appointments.length}}
      </div>
    </div>
    <div class="flex align-items-stretch flex-1 overflow-auto">
      <div class="sideline-container">
        <div class="sideline"></div>
      </div>
      <div class="flex-1">
        <div
          *ngFor="let appointment of myDayView.beyondNextRelevant.appointments">
          <app-appointment-my-day
            (handleSwitchView)="handleSwitchView($event)"
            [appointment]="appointment" />
        </div>
        <div [ngStyle]="{height: '8px'}"></div>
      </div>
    </div>
  </div>
</div>
